import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.scss";

const Header = () => (
  <header class={style.header}>
    <a href="/" class={style.logo}>
      <h1>
        <img src="../../assets/icons/favicon-16x16.png" alt="logo" />
        RW
      </h1>
    </a>
    <nav>
      <Link activeClassName={style.active} href="/games">
        Games
      </Link>
    </nav>
  </header>
);

export default Header;
