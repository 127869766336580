import { h } from "preact";
import style from "./style.scss";
import { Link } from "preact-router/match";

const Footer = () => {
  return (
    <footer>
      <div class={style["footer-links"]}>
        <ul>
          <li>
            <Link href="/games">Games</Link>
          </li>
          <li>
            <Link href="/about">About</Link>
          </li>
          <li>
            <Link href="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
      <div class={style.copy}>
        © {new Date().getFullYear()} Rogue Wave Game Studios
      </div>
    </footer>
  );
};

export default Footer;
