import { h } from "preact";
import { useEffect } from "preact/hooks";
import { Route, Router } from "preact-router";

import Header from "./header";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Games from "../routes/games";
import Bloop from "../routes/bloop";
import About from "../routes/about";
import Contact from "../routes/contact";
import Footer from "./footer";

const App = () => {
  useEffect(() => {
    document.title = "Rogue Wave Game Studios";
  }, []);

  return (
    <div id="app">
      <Header />
      <main>
        <Router onChange={() => window.scrollTo(0, 0)}>
          <Route path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/games" component={Games} />
          <Route path="/bloop/:page?" component={Bloop} />
        </Router>
      </main>
      <Footer />
    </div>
  );
};

export default App;
